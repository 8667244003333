<template>
  <div class="container-full flex-col items-center bg-white">
    <h2 class="mb-3">Loo uus ettevõte</h2>
    <div
      class="bg-attention rounded-md w-full p-5 flex mb-3"
      v-if="displayVerificationNotice"
    >
      <div class="w-1/12 flex">
        <span class="typcn typcn-info-large text-4xl"></span>
      </div>
      <div class="w-11/12 flex items-center">
        Enne ettevõtte loomist kinnitage meilikonto aadress!
      </div>
    </div>
    <div class="flex-col flex w-full mobile:flex-wrap items-center">
      <div class="flex flex-col mobile:w-full w-6/12 mobile:px-2 mb-5">
        <form-label :heading-level="3" title="Ettevõtte detailid"></form-label>
        <div class="flex flex-col">
          <form-label
            title="Nimi"
            :required="true"
            :valid="$v.company.company_name.required"
            :error="$v.company.company_name.$error"
          ></form-label>
          <div class="flex relative mb-3">
            <input
              class="flex w-full pr-8"
              :class="
                $v.$error && !$v.company.company_name.required
                  ? 'shadow-dangeroutline'
                  : ''
              "
              type="text"
              v-model="company.company_name"
              data-cy="titleField"
            />
            <span
              :class="titleCharLimit < 0 ? 'text-danger' : 'disabled'"
              class="absolute company-title-char-limit self-center"
              >{{ titleCharLimit }}</span
            >
          </div>
        </div>
        <div class="flex flex-col">
          <form-label
            title="Registrikood"
            :required="true"
            :valid="$v.company.register_code.required"
            :error="$v.company.register_code.$error"
          ></form-label>
          <div class="flex relative mb-3">
            <input
              class="flex w-full pr-8"
              type="text"
              :class="
                $v.$error && !$v.company.register_code.required
                  ? 'shadow-dangeroutline'
                  : ''
              "
              v-model="company.register_code"
            />
          </div>
        </div>
        <div class="flex flex-col">
          <form-label title="KMRK kood"></form-label>
          <div class="flex relative mb-3">
            <input
              class="flex w-full pr-8"
              type="text"
              v-model="company.tax_code"
            />
          </div>
        </div>
        <div class="flex flex-col relative mb-3">
          <form-label
            title="Meiliaadress"
            :required="true"
            :valid="$v.company.company_email.required"
            :error="$v.company.company_email.$error"
          ></form-label>
          <input
            v-if="!ownerEmailAddress"
            class="flex w-full pr-8 mb-3"
            type="text"
            :class="
              $v.$error && !$v.company.company_email.required
                ? 'shadow-dangeroutline'
                : ''
            "
            v-model="companyEmailInput"
            :placeholder="
              ownerEmailAddress ? $store.state.userData.user.email : ''
            "
          />
          <span
            v-if="ownerEmailAddress"
            class="bg-offwhite w-full rounded-xl py-2 px-3 mb-3 shadow cursor-default"
            >{{ company.company_email }}</span
          >
          <div class="flex items-center">
            <div class="flex">
              <base-checkbox
                :base-val="ownerEmailAddress"
                @valueChanged="ownerEmailAddress = $event"
              />
            </div>
            <div class="flex pl-3">
              <span>
                Kasuta selle konto meiliaadressi ({{
                  $store.state.userData.user.email
                }})
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mobile:w-full w-6/12 flex-col mobile:px-2 mb-5">
        <form-label
          title="Juriidiline aadress"
          :required="true"
          :valid="$v.company.location.required"
          :error="$v.company.location.$error"
        ></form-label>
        <div class="flex flex-row items-center"></div>
        <location-inaddress
          v-if="!company.location"
          :class="
            $v.$error && !$v.company.location.required
              ? 'shadow-dangeroutline'
              : ''
          "
          :text="'Sisestage ettevõtte juriidiline aadress'"
          @locationChanged="handleLocationChange"
          class="mb-2"
        >
        </location-inaddress>
        <div v-if="company.location" class="mb-2 flex">
          {{ company.location.ipikkaadress
          }}<button
            class="flex p-0 items-center my-auto ml-2 leading-none bg-offwhite hover:bg-dangerdark text-darkgrey hover:text-white hover:shadow-dangeroutline rounded-full"
            v-if="company.location"
            @click="company.location = null"
          >
            <span
              class="rounded-gwdfull bg-danger flex p-1 typcn-edit typcn text-white"
            ></span>
            <span class="font-medium pl-2 pr-3 text-sm">Tühjenda</span>
          </button>
        </div>
        <div
          v-if="company.location && mapInited"
          class="flex h-full w-full shadow rounded-xl mb-3 mobile:hidden"
          style="min-height: 300px;"
        >
          <l-map
            class="h-full w-full z-10 rounded-xl"
            :options="mapOptions"
            :center="mapOptions.center"
            :interactive="false"
          >
            <l-tile-layer
              :url="tileUrl"
              :attribution="'© OpenStreetMap contributors'"
              :position="'bottomleft'"
              class="h-full w-full justify-start flex"
            ></l-tile-layer>
            <l-polygon :lat-lngs="polygonLatLngs"></l-polygon>
            <l-marker :lat-lng="[locationMarker.lat, locationMarker.long]">
              <l-icon
                icon-url="/bc21/placeholder.svg"
                :icon-size="dynamicSize"
              ></l-icon>
            </l-marker>
          </l-map>
        </div>
      </div>
    </div>
    <div
      class="flex w-6/12 mobile:w-full mx-auto mb-5 justify-center items-start"
    >
      <div class="flex flex-col w-full mr-5 mobile:mr-0 self-center">
        <div class="w-full error-box mb-4" v-if="error" id="errorBox">
          <div class="flex w-1/12 justify-center items-center">
            <span
              class="typcn typcn-times text-white text-4xl items-center"
            ></span>
          </div>
          <div class="flex flex-col w-11/12 justify-center">
            <span class="flex" v-if="!$v.company.company_name.required"
              >Ettevõtte nimi ei tohi olla tühi</span
            >
            <span class="flex" v-if="!$v.company.company_name.maxLength"
              >Etteõtte nimi ei tohi olla pikem kui 128 tähemärki</span
            >
            <span class="flex" v-if="!$v.company.location.required"
              >Palun sisestage aadress</span
            >
            <span class="flex" v-if="errorText" v-html="errorText"></span>
            <div class="flex" v-if="inactiveAccount">
              <button
                @click="resendEmailConfirmActive = true"
                class="mt-4 new-button-green label"
              >
                Saada konto aktivatsioonimeil uuesti
              </button>
            </div>
          </div>
        </div>
        <button
          @click="submitDisabled ? emphasizeRequirements() : createCompany()"
          data-cy="formSubmit"
          :class="submitDisabled ? 'new-button-disabled' : 'new-button-green'"
          class="self-center"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta ettevõte</span>
        </button>
      </div>
    </div>
    <confirm-modal
      v-if="resendEmailConfirmActive"
      text="Olete kindel, et soovite konto aktiveerimismeili uuesti saata?"
      @confirm="resendEmailConfirm"
      @closeModal="resendEmailConfirmActive = false"
    ></confirm-modal>
  </div>
</template>

<script>
import { email, maxLength, required } from "vuelidate/lib/validators";
import RequestHandler from "@/assets/mixins/RequestHandler";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LIcon, LMarker, LPolygon } from "vue2-leaflet";

export default {
  name: "PostCompany",
  mixins: [RequestHandler],
  components: {
    LocationInaddress,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPolygon,
    ConfirmModal
  },
  data() {
    return {
      error: false,
      displayVerificationNotice: false,
      displaySubmitNotice: true,
      companyEmailInput: "",
      inactiveAccount: false,
      company: {
        company_name: "",
        register_code: "",
        tax_code: null,
        company_email: "",
        location: null,
        iban_code: "",
        iban_account_name: ""
      },
      ownerEmailAddress: false,
      companyUUID: null,
      errorText: "",
      tileUrl: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      mapOptions: {
        zoom: 6,
        center: latLng(58.5953, 25.0136),
        interactive: false
      },
      locationMarker: {
        lat: "00.000",
        long: "00.000"
      },
      iconSize: 16,
      mapInited: false,
      resendEmailConfirmActive: false
    };
  },
  mounted() {},
  methods: {
    handleLocationChange(e) {
      this.company.location = e;
      this.mapOptions.zoom = 18;
      this.locationMarker.lat =
        e.viitepunkt_b.substring(0, 2) + "." + e.viitepunkt_b.substring(3);
      this.locationMarker.long =
        e.viitepunkt_l.substring(0, 2) + "." + e.viitepunkt_l.substring(3);
      this.mapOptions.center = latLng(
        e.viitepunkt_b.substring(0, 2) + "." + e.viitepunkt_b.substring(3),
        e.viitepunkt_l.substring(0, 2) + "." + e.viitepunkt_l.substring(3)
      );
      this.mapInited = true;
    },
    toggleCompanyEmail() {
      if (this.ownerEmailAddress) {
        this.ownerEmailAddress = false;
        this.company.company_email = "";
      } else {
        this.ownerEmailAddress = true;
        this.company.company_email = this.$store.state.userData.user.email;
      }
    },
    emphasizeRequirements() {
      this.$v.$touch();

      let reqBox = document.getElementById("requirementBox");

      reqBox.classList.add("shake");

      setTimeout(function() {
        reqBox.classList.remove("shake");
      }, 1000);
    },
    createCompany() {
      this.$store.state.loading = true;
      let company_data = {};
      company_data.company_name = this.company.company_name;
      company_data.company_information = {};
      company_data.company_information.company_email = this.company.company_email;
      company_data.company_information.register_code = this.company.register_code;
      company_data.company_information.tax_code = this.company.tax_code;
      company_data.company_information.location = this.company.location;
      this.$v.$touch();
      this.inactiveAccount = false;
      this.apiRequest("company/new/", "post", true, company_data).then(res => {
        if (res.status === 201) {
          let newCompanyId = res.data.company_uuid;
          this.$store.dispatch("companyData/retrieveCompanyData").then(res => {
            if (res) {
              this.$store.state.loading = false;
              this.$router.push("/calendar/");
              this.$store.dispatch("companyData/activateCompany", newCompanyId);
              this.$store.dispatch("userData/retrieveProfileData");
              this.$store.dispatch("companyData/openTrialPopup");
            }
          });
        } else {
          const resObj = { res };
          if (resObj.res.response.status === 409) {
            this.error = true;
            this.errorText = resObj.res.response.data.text;
            this.$store.state.loading = false;
            this.$nextTick(() => {
              document.getElementById("errorBox").scrollIntoView();
            });
          } else if (resObj.res.response.status === 403) {
            this.error = true;
            this.errorText =
              "<span>Et ettevõtet salvestada, aktiveerige oma konto. Juhul, kui te vastavat e-kirja saanud ei ole, vajutage alumisele nupule ning vaadake ka oma rämpspostikausta. Peale konto aktiveerimist vajutage uuesti Salvesta nupule.</span>";
            this.$store.state.loading = false;
          } else {
            this.$store.state.loading = false;
          }
        }
      });
    },
    resendEmailConfirm() {
      this.apiRequest(
        "authentication/user/resend/" +
          this.$store.state.userData.user.id +
          "/",
        "get",
        true
      ).then(res => {
        res;
      });
      this.resendEmailConfirmActive = false;
    }
  },
  computed: {
    titleCharLimit() {
      return 128 - this.company.company_name.length;
    },
    submitDisabled() {
      return !!this.$v.company.$invalid;
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    polygonLatLngs() {
      if (this.company.location) {
        let formattedArray = this.company.location.g_boundingbox;

        formattedArray = formattedArray.split(" ");
        formattedArray = formattedArray.map(x => x.split(","));

        return formattedArray;
      } else {
        return null;
      }
    }
  },
  watch: {
    companyEmailInput(val) {
      if (this.ownerEmailAddress) {
        this.company.company_email = this.$store.state.userData.user.email;
      } else {
        this.company.company_email = val;
      }
    },
    ownerEmailAddress(val) {
      val
        ? (this.company.company_email = this.$store.state.userData.user.email)
        : (this.company.company_email = this.companyEmailInput);
    }
  },
  validations: {
    company: {
      company_email: { required, email },
      company_name: { required, maxLength: maxLength(128) },
      register_code: { required },
      location: {
        required
      }
    }
  }
};
</script>

<style scoped lang="scss">
.company-title-char-limit {
  right: 1%;
}
</style>
